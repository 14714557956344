import * as React from "react"
import * as styles from "./header.module.css"
import { Link } from "gatsby"
import PropTypes from "prop-types"

const Header = ({ siteTitle }) => (
  <header className={`gutter ${styles.container}`}>
      <h1 className={styles.title}><Link to="/">{siteTitle}</Link></h1>
      <a href="mailto:hello@derrry.com" className={styles.title}>hello@derrry.com</a>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
