import * as React from "react"
import * as styles from "./home.module.css"
import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <Seo title="Home" />

      <div className={`gutter`}>
        <div className={styles.introContainer}>
          <div className={styles.introBlock}>
            <p className={styles.intro}>I’m a product designer and art director based in London, UK. I like a good problem to solve. Hopefully bringing a little charm and resourcefulness to improve a small part of our lives.</p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
